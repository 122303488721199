import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import config from './config.js';

const KilosStockDeheza = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`http://www.sofliweb.net:3100/carni-rio`);
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      
      <table>
        <thead>
          <tr>
            <th>Código</th>
            <th>Descripción</th>
            <th>Total Kilos en Stock</th>
          </tr>
        </thead>
        <tbody>
          {data
            .filter((product) => {
              const totalKilosStock = parseFloat(product.TOTAL_KILOS_STOCK_PROD);
              return totalKilosStock !== 0 && !isNaN(totalKilosStock) && totalKilosStock !== '';
            }) // Filtrar productos con total kilos en stock distinto de cero, no NaN y no vacío
            .map((product, index) => (
              <tr key={index}>
                <td>{product.ART_CODIGO}</td>
                <td>{product.ART_DENOM}</td>
                <td>{parseFloat(product.TOTAL_KILOS_STOCK_PROD).toFixed(2)}</td>
              </tr>
            ))}
        </tbody>


      </table>
    </div>
  );
};

export default KilosStockDeheza;
