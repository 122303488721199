import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './App.css';
import './tableStyles.css';
//import config from './config.js'; // Importa la configuración
import DetalleDeheza from './DetalleVtaDeheza.js'; // Importa el componente DetalleDeheza
import KilosStockDeheza from './KilosStock-Deheza.js'; // Importa el componente KilosStockDeheza

const CarniDeheza = () => {
  const [dataFromServer, setDataFromServer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showStockDetail, setShowStockDetail] = useState(false);
  const [showSoldDetail, setShowSoldDetail] = useState(false);
  //const [showRepositionDetail, setShowRepositionDetail] = useState(false);
  //const [showDetalleDeheza, setShowDetalleDeheza] = useState(false); // Nueva variable de estado

  
  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`http://www.sofliweb.net:3100/carni`);
      setDataFromServer(response.data);
      setLoading(false);
      console.log(response.data);
    } catch (error) {
      setLoading(false);
      console.error('Error fetching data:', error);
    }
  };
  

  useEffect(() => {
    fetchData();
  }, []);

  const toggleStockDetail = () => {
    setShowStockDetail(!showStockDetail);
    // setShowDetalleDeheza(false); // Elimina esta línea
  };
  
  const toggleSoldDetail = () => {
    setShowSoldDetail(!showSoldDetail);
    // setShowDetalleDeheza(false); // Elimina esta línea
  };
  
 

  return (
    <>
      <div className="container">
        {loading ? (
          <p>Obteniendo datos...</p>
        ) : dataFromServer && dataFromServer.length > 0 ? (
          <div className="dataContainer">
            <h2 className='center-align'>Datos del Dia - DEHEZA</h2>
            <hr></hr>

            {dataFromServer.map((item, index) => (
              <div key={index}>
                {item.TOTAL_KILOS_STOCK && (
                  <p className="text-container">
                    Kilos en Stock: {parseFloat(item.TOTAL_KILOS_STOCK).toFixed(2)}
                    <button className="button-detail" onClick={toggleStockDetail}>
                      {showStockDetail ? 'Ocultar Detalles' : 'Ver Detalles'}
                    </button>
                    {showStockDetail && <KilosStockDeheza />}
                  </p>
                )}

                {item.TOTAL_KILOS_VENDIDOS && (
                  <p className="text-container">
                    Kilos Vendidos: {parseFloat(item.TOTAL_KILOS_VENDIDOS).toFixed(2)}
                    <button className="button-detail" onClick={toggleSoldDetail}>
                      {showSoldDetail ? 'Ocultar Detalles' : 'Ver Detalles'}
                    </button>
                    {showSoldDetail && <DetalleDeheza />}
                  </p>
                )}

                {item.TOTAL_REMITOS_INT && (
                  <p className="text-container">
                    Kilos de Reposicion: {parseFloat(item.TOTAL_REMITOS_INT).toFixed(2)}
                  </p>
                )}

              </div>
            ))}
          </div>
        ) : (
          <p>No hay datos disponibles</p>
        )}
        <div className="button-container">
          <button className="button" onClick={fetchData} disabled={loading}>
            {loading ? 'Actualizando...' : 'Actualizar'}
          </button>
          <Link to="/masinfo-Deheza" className="mas-info-button">Más Info</Link>
        </div>
      </div>
      <Link to="/" className="button">Volver al Inicio</Link>
    </>
  );
};

export default CarniDeheza;
