import React, { useState, useEffect } from 'react';
import axios from 'axios';
//import config from './config.js';

const DetalleRioIV = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`http://www.sofliweb.net:3100/carni-rio`);
        setData(response.data);
      } catch (error) {
        console.error('Error Obteniendo datos:', error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      
      <table>
        <thead>
          <tr>
            <th>Código</th>
            <th>Descripción</th>
            <th>Total Kilos Vendidos</th>
          </tr>
        </thead>
        <tbody>
          {data
            .filter((product) => {
              const totalKilosVendidos = parseFloat(product.TOTAL_KILOS_VENDIDOS_ART);
              return totalKilosVendidos !== 0 && !isNaN(totalKilosVendidos) && totalKilosVendidos !== '';
            })
            .map((product, index) => (
              <tr key={index}>
                <td>{product.ART_CODIGO}</td>
                <td>{product.ART_DENOM}</td>
                <td>{parseFloat(product.TOTAL_KILOS_VENDIDOS_ART).toFixed(2)}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default DetalleRioIV;
