import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import './faenaStyles.css';
//import config from './config.js';

const Faena = () => {
    const [data, setData] = useState([]);
    const [totalCabezas, setTotalCabezas] = useState(0);
    const [totalKilosRomaneo, setTotalKilosRomaneo] = useState(0);
    const [filtroEspecie, setFiltroEspecie] = useState(null);

    const actualizarDatos = () => {
      axios.get(`http://www.sofliweb.net:3100/faena`)
        .then(response => {
          setData(response.data);
        })
        .catch(error => {
          console.error('Error al obtener los datos:', error);
        });
    };

    useEffect(() => {
      actualizarDatos();
    }, []);

    useEffect(() => {
      if (data.length > 0) {
        let filteredData = data;
        if (filtroEspecie !== null) {
          filteredData = data.filter(item => item["ESP_CODIGO"] === filtroEspecie);
        }
        const cabezasTotal = filteredData.reduce((total, item) => total + item["FAE_CABEZAS"], 0);
        const kilosRomaneoTotal = filteredData.reduce((total, item) => total + item["ROM_KILOS"], 0);
        setTotalCabezas(cabezasTotal);
        setTotalKilosRomaneo(kilosRomaneoTotal);
      }
    }, [data, filtroEspecie]);

    const exportToExcel = () => {
      const dataToExport = data.map(item => ({
        Fecha: item["FAE_FECHA"].split("T")[0],
        Especie: item["ESP_CODIGO"],
        Tropa: item["TRO_NUMERO"],
        Usuario: item["USU_CODIGO"],
        Den_Usuario: item["USU_DENOM"],
        Cabezas:item["FAE_CABEZAS"],
        Kilos_Romaneo: item["ROM_KILOS"],
        Prov_Tropa: item["TROPA_PROV"],
      }));

      const worksheet = XLSX.utils.json_to_sheet(dataToExport);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Datos');
      XLSX.writeFile(workbook, 'datos.xlsx');
    };

    const aplicarFiltro = (codigo) => {
      setFiltroEspecie(codigo);
    };

    const volver = () => {
      window.history.back();
    };

    return (
      <div>
        <div>
         <button onClick={volver} className="boton-volver">Volver</button>
         
          <button onClick={() => aplicarFiltro(1)} className="boton-especie">Vacuno</button>
          <button onClick={() => aplicarFiltro(2)} className="boton-especie">Porcino</button>
          <button onClick={() => aplicarFiltro(null)} className="boton-especie">Todos</button>
          <button onClick={actualizarDatos} className="boton-actualizar">Actualizar Datos</button>
          
        </div>
        <table>
          <thead>
            <tr>
              <th>Fecha</th>
              <th>Especie</th>
              <th>Tropa</th>
              <th>Usuario</th>
              <th>Den-Usuario</th> 
              <th>Cabezas</th>
              <th>Garron Desde</th>
              <th>Garron Hasta</th>
              <th>Ultimo Garron</th>
              <th>Kilos Romaneo</th>
              <th>Proveedor Tropa</th>
            </tr>
          </thead>
          <tbody>
            {data.map(item => {
              if (filtroEspecie === null || item["ESP_CODIGO"] === filtroEspecie) {
                return (
                  <tr key={item["FAE_NUMERO"]}>
                    <td>{item["FAE_FECHA"].split("T")[0]}</td>
                    <td>
                      {item["ESP_CODIGO"] === 1
                      ? "Vacuno"
                      : item["ESP_CODIGO"] === 2
                      ? "Porcino"
                      : item["ESP_CODIGO"]}
                    </td>
                    <td>{item["TRO_NUMERO"]}</td>
                    <td>{item["USU_CODIGO"]}</td>
                    <td className="alineado-izquierda">{item["USU_DENOM"]}</td>
                    <td>{item["FAE_CABEZAS"]}</td>
                    <td>{item["GARROND"]}</td>
                    <td>{item["GARRONH"]}</td>
                    <td>{item["ROM_GARRON"]}</td>
                    <td>{item["ROM_KILOS"]}</td>
                    <td className="alineado-izquierda">{item["TROPA_PROV"]}</td>
                  </tr>
                );
              }
              return null;
            })}
          </tbody>
          <tfoot className='pie-de-tabla'>
            <tr>
              <td colSpan={5}>Total:</td>
              <td>{totalCabezas}</td>
              <td colSpan={3}></td>
              <td>{totalKilosRomaneo}</td>
            </tr>
            <td>
              <button onClick={exportToExcel} className="boton-exportar">Exportar a Excel</button>
            </td>
          </tfoot>
        </table>
      </div>
    );
};

export default Faena;

