import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import CarniDeheza from './Carni-Deheza.js';
import CarniRio from './Carni-RioIV.js';
import FrontPage from './FrontPage.js';
import logo from './LogoFridezaBlanco.png';
import MasInfoDza from './masinfo-Deheza.js';
import MasInfoRio from './masinfo-RioIV.js';
import Faena from './faena.js';
import './App.css';

const App = () => {
   

  return (
    <Router>
      <div>
        <header>
          <img src={logo} alt="Frideza Logo" className="logo" />
          {/*<h1 className='titulo-carni'>Frigorifico General Deheza S.A.</h1>*/}
          
        </header>
        <hr></hr>
        <Routes>
          <Route path="/" element={<FrontPage />} />
          <Route path="/Carni-Deheza" element={<CarniDeheza />} />
          <Route path="/Carni-RioIV" element={<CarniRio />} />
          <Route path="/masinfo-Deheza" element={<MasInfoDza />} /> 
          <Route path="/masinfo-RioIV" element={<MasInfoRio />} /> 
          <Route path="/faena" element={<Faena />} /> 
        </Routes>
        <footer>
          <p className='footer'>Guille-Castillo</p>
        </footer>
      </div>
    </Router>
  );
};

export default App;
