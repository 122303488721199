import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './tableStyles.css';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const MasInfoDeheza = () => {
  const [masInfoData, setMasInfoData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [totalMes, setTotalMes] = useState(0);

  const fetchData = async (year, month) => {
    try {
      const masinfoResponse = await fetch(`http://www.sofliweb.net:3100/masinfo?year=${year}&month=${month}`);
      const masinfoData = await masinfoResponse.json();
      setMasInfoData(masinfoData);
      setLoading(false);
    } catch (error) {
      console.error('Error obteniendo datos:', error);
      setLoading(false);
    }
  };

  useEffect(() => {
    const year = selectedDate.getFullYear();
    const month = selectedDate.getMonth() + 1;
    fetchData(year, month);
  }, [selectedDate]);

  useEffect(() => {
    const total = masInfoData.reduce((acc, day) => {
      if (day.FECHA.slice(0, 7) === selectedDate.toISOString().slice(0, 7)) {
        return acc + day.TOTAL_KILOS_VENDIDOS;
      }
      return acc;
    }, 0);

    setTotalMes(total);
  }, [masInfoData, selectedDate]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <Link to="/Carni-RioIV" className="button">Volver a la página anterior</Link>
      <div className="container">
        <h1 className='center-align'>Ventas Diarias - Total Mensual</h1>
        <h3>Seleccione un Día</h3>
        <Calendar
          onChange={handleDateChange}
          value={selectedDate}
        />
        {loading ? (
          <p>Obteniendo datos...</p>
        ) : (
          <div className="dataContainer">
            {masInfoData.map((day, index) => (
              <div key={index}>
                {day.FECHA === selectedDate.toISOString().slice(0, 10) && (
                  <div>
                    <p>Fecha: {day.FECHA}</p>
                    <p>Total Kilos Vendidos: {day.TOTAL_KILOS_VENDIDOS.toFixed(2)}</p>
                  </div>
                )}
              </div>
            ))}
            <hr></hr>
            <p>Total del mes: {totalMes.toFixed(2)}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default MasInfoDeheza;
