import React from 'react';
import { Link } from 'react-router-dom';
import './FrontPage.css';

const FrontPage = () => {
  return (
    <div className="front-page">
      <h1> </h1>
      <div className="tiles">
        <Link to="/Carni-Deheza" className="tile carnideheza">
          <h2>Carnicería Deheza</h2>
          <p>Información de Stock y Ventas</p>
        </Link>
        <Link to="/Carni-RioIV" className="tile carnirioiv">
          <h2>Carnicería Rio IV</h2>
          <p>Información de Stock y Ventas</p>
        </Link>
        <Link to="/faena" className="tile faena">
          <h2>Faena</h2>
          <p>Estado de Faena Diaria</p>
        </Link>
        <Link to="/ctacte" className="tile ctacte">
          <h2> Ctas Ctes</h2>
          <p>CC</p>
        </Link>
        <Link to="/gfin" className="tile G-Fin">
          <h2> G-F</h2>
          <p></p>
        </Link>
      </div>
    </div>
  );
};

export default FrontPage;



